export function useStoryblokMode() {
  const route = useRoute()

  const {
    public: { storyblokMode },
  } = useRuntimeConfig()

  const storyblokContentVersion = computed(() => {
    if (route.fullPath.includes('/preview/'))
      return 'draft'

    const isPublishedPreviewMode = route.query._storyblok_published

    if (isPublishedPreviewMode)
      return 'published'

    const isDraftPreviewMode = route.query._storyblok

    if (isDraftPreviewMode)
      return 'draft'

    return storyblokMode as 'draft' | 'published'
  })

  return {
    storyblokContentVersion,
  }
}
