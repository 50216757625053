import { storefrontsConfiguration } from '~/configuration/storefronts.configuration'

export function useLanguage () {
  const route = useRoute()

  if (!route.fullPath.includes('/preview/')) {
    const { $currentStorefront } = useNuxtApp()

    return {
      language: $currentStorefront.contentLocale,
    }
  }

  let language = 'default'

  storefrontsConfiguration.forEach((storefront) => {
    const storeFrontLanguage = storefront.contentLocale

    if (storeFrontLanguage === 'default')
      return

    if (route.fullPath.includes(storeFrontLanguage))
      language = storeFrontLanguage
  })

  return {
    language,
  }
}
